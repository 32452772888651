import { NextPage } from "next";
import dynamic from "next/dynamic";
import React from "react";
import { useSelector } from "react-redux";

import { organizationSelector } from "../store/organization";

const WestminsterIndex = dynamic(() => import(`./westminster/index`), {
  ssr: false,
});
const MountainHouseIndex = dynamic(() => import(`./mountainhouse/index`), {
  ssr: false,
});
const SantaRosaIndex = dynamic(() => import(`./santarosa/index`), {
  ssr: false,
});
const WeymouthIndex = dynamic(() => import(`./weymouth/index`), {
  ssr: false,
});
const CastlePinesIndex = dynamic(() => import(`./castlepines/index`), {
  ssr: false,
});
const SanAntonioIndex = dynamic(() => import(`./sanantonio/index`), {
  ssr: false,
});
const ParkerIndex = dynamic(() => import(`./parker/index`), {
  ssr: false,
});
const MontereyParkIndex = dynamic(() => import(`./montereypark/index`), {
  ssr: false,
});
const EvanstonIndex = dynamic(() => import(`./evanston/index`), {
  ssr: false,
});
const MetroParksIndex = dynamic(() => import(`./metroparks/index`), {
  ssr: false,
});
const CrownMountainIndex = dynamic(() => import(`./crownmountain/index`), {
  ssr: false,
});
const CCMRDIndex = dynamic(() => import(`./ccmrd/index`), {
  ssr: false,
});
const PimaIndex = dynamic(() => import(`./pima/index`), {
  ssr: false,
});
const TulareIndex = dynamic(() => import(`./tulare/index`), {
  ssr: false,
});
const RedwoodCityIndex = dynamic(() => import(`./redwoodcity/index`), {
  ssr: false,
});
const KenCarylIndex = dynamic(() => import(`./kencaryl/index`), {
  ssr: false,
});
const NorthvilleIndex = dynamic(() => import(`./northville/index`), {
  ssr: false,
});
const SarasotaIndex = dynamic(() => import(`./sarasota/index`), {
  ssr: false,
});

const Home: NextPage = () => {
  const organization = useSelector(organizationSelector);
  return (
    <>
      {organization === "mountainhouse" && <MountainHouseIndex />}
      {organization === "westminster" && <WestminsterIndex />}
      {organization === "santarosa" && <SantaRosaIndex />}
      {organization === "weymouth" && <WeymouthIndex />}
      {organization === "castlepines" && <CastlePinesIndex />}
      {organization === "sanantonio" && <SanAntonioIndex />}
      {organization === "parker" && <ParkerIndex />}
      {organization === "montereypark" && <MontereyParkIndex />}
      {organization === "evanston" && <EvanstonIndex />}
      {organization === "metroparks" && <MetroParksIndex />}
      {organization === "crownmountain" && <CrownMountainIndex />}
      {organization === "ccmrd" && <CCMRDIndex />}
      {organization === "pima" && <PimaIndex />}
      {organization === "tulare" && <TulareIndex />}
      {organization === "redwoodcity" && <RedwoodCityIndex />}
      {organization === "kencaryl" && <KenCarylIndex />}
      {organization === "northville" && <NorthvilleIndex />}
      {organization === "sarasota" && <SarasotaIndex />}
    </>
  );
};

export default Home;
